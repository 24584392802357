import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useStore } from 'react-context-hook'
import { useQueryParam, NumberParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTranslations from '/src/hooks/api/translations'
import Layout from '/src/ui/core/layouts/layout'
import InspectionModel from '/src/models/inspection'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import InspectionsSidePanel from '/src/ui/domain/inspections/inspections_side_panel'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import '/src/static/css/core/grid/grid.css'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { editMenuItem, duplicateMenuItem, deleteMenuItem } from '/src/ui/core/grid/context_menu_entries'
import ChooseFormModal from '/src/ui/core/popups/choose_form_modal'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import { MdControlPoint } from 'react-icons/md'
import { GrSchedules } from 'react-icons/gr'
import { buildBackURL } from '/src/utils/url'
import I18n from '/src/utils/translations.js'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import '/src/static/css/core/layouts/shadowed_page.css'

export default function Inspections() {
  const model = new InspectionModel()
  const templateId = useQueryParamWithPrevious('eav_template_id')
  const [requestId] = useQueryParam('request_id', NumberParam)
  const { filter, gridTitle, labels } = useRequestGridFilter(requestId, model.route, null, templateId)
  const history = useHistory()
  const { status, responseData, fetchAPI } = useFetchAPI('eav_sections')
  const loadingTranslations = useTranslations()
  const [inspectStatuses] = useStore('inspect_statuses')
  const statuses = inspectStatuses ? Object.values(inspectStatuses) : []
  const pendingInspection = statuses.find((e) => e.i18n_id === 'pending')

  const [selectedItem, setSelectedItem] = useState()
  const [sectionsColumns, setSectionsColumns] = useState([])
  const [sidePanelDataItem, setSidePanelDataItem] = useState()
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [chooseFormModalVisibility, setChooseFormModalVisibility] = useState(false)

  const isPending = (data) => (
    pendingInspection && (data.inspect_status_id === pendingInspection.id)
  )

  const handleCloseSidePanel = () => {
    setSelectedItem()
    setSidePanelDataItem()

    dispatch(BusEvents.SIDE_PANEL_CLOSED)

    setColumnStyles(SIDE_PANEL_CLOSED)
  }

  const onRowClick = useCallback((e) => {
    setSelectedItem((prevSelectedItem) => {
      const isSidePanelOpened = prevSelectedItem && prevSelectedItem.id === e.dataItem.id
      const newItem = isSidePanelOpened ? undefined : e.dataItem

      setSidePanelDataItem(newItem)
      return newItem
    })

    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  const actions = [
    editMenuItem(history, model.route, isPending),
    duplicateMenuItem(history, model.route),
    deleteMenuItem(history, model, showConfirmation, handleCloseSidePanel)
  ]

  useEffect(() => {
    if (!templateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: templateId } }
    }

    fetchAPI(params)
    handleCloseSidePanel()
  }, [templateId])

  useEffect(() => {
    if (loadingTranslations || status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(responseData.data[0])
    setSectionsColumns(newSectionsColumns)
  }, [status, responseData, loadingTranslations])

  useBus(
    BusEvents.SIDEBAR_ADD_BUTTON_CLICKED,
    () => {
      if (!templateId) {
        notifyError(I18n.t('templates.no_template'))
        return
      }
      setChooseFormModalVisibility(true)
    }, [templateId])

  const backURLConfig = () => {
    return { eavTemplateId: templateId, requestId }
  }

  const chooseInspectionsButtons = {
    buttons: [
      {
        route: model.route,
        action: 'new',
        query: buildBackURL(backURLConfig()),
        title: I18n.t('inspections.choose_modal.single'),
        subtitle: I18n.t('inspections.choose_modal.single_description'),
        icon: () => <MdControlPoint />
      },
      {
        route: model.route,
        action: 'schedule',
        query: buildBackURL(backURLConfig()),
        title: I18n.t('inspections.choose_modal.schedule'),
        subtitle: I18n.t('inspections.choose_modal.schedule_description'),
        icon: () => <GrSchedules />
      }
    ],
    title: I18n.t('inspections.choose_modal.title')
  }

  return (
    <Layout key={requestId ? `inspections-filter-${requestId}` : 'inspections'} showNewButton>
      {renderConfirmation()}
      <TabsWrapper tabType={model.templateType} />
      <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
        <div className={selectedItem ? 'shadowed-page' : 'unshadowed-page'}>
          {chooseFormModalVisibility && (
            <ChooseFormModal
              onClose={() => setChooseFormModalVisibility(false)}
              opts={chooseInspectionsButtons}
            />
          )}
          <TemplatableGrid
            labels={labels}
            filter={filter}
            gridTitle={gridTitle}
            contextMenuItems={actions}
            model={model}
            icons={[]}
            onRowClick={onRowClick}
            selectedItem={selectedItem}
            selectFiltering={false}
          />
        </div>
        <InspectionsSidePanel
          dataItem={sidePanelDataItem}
          sections={sectionsColumns}
          onClose={handleCloseSidePanel}
          actions={actions}
        />
      </LayoutPanels>
    </Layout>
  )
}
